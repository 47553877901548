<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          label="用户名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入用户名"
            v-decorator="['userName', {rules: [{required: true, message: '请输入用户名！'}]}]"
          />
        </a-form-item>

        <a-form-item
          label="密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          v-if="!(info.id || false)"
        >
          <a-input
            type="password"
            placeholder="请输入密码"
            v-decorator="['password', {rules: [{required: true, message: '请输入密码！'}]}]"
          />
        </a-form-item>

        <a-form-item
          label="姓名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入姓名"
            v-decorator="['realName', {rules: [{required: true, message: '请输入姓名！'}]}]"
          />
        </a-form-item>

        <a-form-item
          label="手机号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入手机号"
            v-decorator="['phone', {rules: [{required: true,validator: phoneVail}]}]"
          />
        </a-form-item>

        <a-form-item
          label="默认部门"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-tree-select
            v-decorator="['defaultDeptId', {rules: [{required: true, message: '请选择部门'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'deptNameView', key:'key', value: 'key' }"
            placeholder="请选择部门"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>


        <a-form-item
          label="电子签名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            :hidden='true'
            v-decorator="['sign']"
          />
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="url"
            :headers='headers'
            @change="handleChange"
          >
            <img v-if="imageUrl"
                 width='100'
                 :src="imageUrl"
                 alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                电子签名
              </div>
            </div>
          </a-upload>

        </a-form-item>

        <a-form-item
          label="状态"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          v-if="(info && info['roleKeyList'] &&info['roleKeyList'][0]!=='admin') || false"
        >
          <a-radio-group v-decorator="['status', {rules: [{required: true, message: '请选择状态'}]}]">
            <a-radio :value="1">
              正常
            </a-radio>
            <a-radio :value="0">
              停用
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          label="排序号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input-number
            placeholder="请输入排序号"
            v-decorator="['sort', {rules: [{required: true, message: '请输入排序号！'}],initialValue: 0}]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { add, deptTree, edit } from '@/api/user-rights/usermanage'
import { getToken } from '@/utils/auth'
import { getBase64, getSidDownloadFile } from '@/utils/util'
import { getBySid } from '@/api/manage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      title: undefined,
      // id: undefined,
      info: {},
      url: process.env.VUE_APP_API_BASE_URL + '/file/tempUpload',
      headers: { 'Authorization': 'Bearer ' + getToken() },
      // 文件
      imageUrl: '',
      loading:false,
      sign:null
    }
  },
  methods: {
    /**
     * 编辑
     */
    edit (record) {

      this.title = '编辑用户信息'
      this.handleDeptTree()
      this.visible = true
      this.info = record || {}
      const fields = ['userName', 'realName', 'phone', 'defaultDeptId', 'sort', 'sign']

      this.$nextTick(() => {
        fields.forEach(v => this.form.getFieldDecorator(v))
        this.form.setFieldsValue({
          userName: record.userName,
          realName: record.realName,
          phone: record.phone,
          defaultDeptId: record.defaultDeptId,
          // status: record.status,
          sort: record.sort,
          sign: record.sign
        })
        if (record.sign){
          this.imageUrl = process.env.VUE_APP_API_BASE_URL
            + '/file/getSource?sid=' + record.sign + '&token=' + getToken()
        }else {
          this.imageUrl = null
        }
        if (this.info?.roleKeyList[0] !== 'admin') {
          this.form.setFieldsValue({
            status: record.status
          })
        } else {
          // admin 状态
          this.status = record.status
        }
      })
      // this.id = record.id

    },
    /**
     * 新增
     */
    add (record) {
      this.title = '新增用户信息'
      this.handleDeptTree()
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue({
          defaultDeptId: null
        })
      }, 100)
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (this.info.id) {
            this.editSubmit(values)
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },

    /**
     *编辑提交
     */
    editSubmit (values) {
      if (this.info?.roleKeyList[0] === 'admin') {
        values = Object.assign(values, { status: this.status })
      }
      edit(Object.assign(values, {
        id: this.info.id ,
        sign: this.sign
      })).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('编辑成功')
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    /**
     * 添加提交
     */
    addSubmit (values) {
      values.sign = this.sign
      add(values).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('新增成功')
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.title = undefined
      // this.id = undefined
      this.info = {}
    },
    /**
     * taidi
     * 获取组织树信息
     */
    handleDeptTree () {
      this.loading = true
      deptTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.treeData = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    phoneVail (rule, value, callback) {
      if (/^1[3-9]\d{9}$/.test(value)) {
        callback()
      } else if (value === null || value === undefined || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入手机号')
      } else {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正确的手机号')
      }
    },
    /**
     * 文件发生修改
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        console.log(info,"info")
        if (info?.file?.response?.code === 200){
          getBase64(info.file.originFileObj, imageUrl => {
            this.imageUrl = imageUrl;
            this.loading = false;
          });
          this.sign = info.file?.response?.data?.sid
        }

      }
    },
    /**
     * 下载文件
     * @param file
     */
    download(file) {
      if (!(file.sid || file.response?.data?.sid)) {
        this.$notification.error({
          message: '错误提示',
          description: '尚未找到该文件'
        })
      }
      getSidDownloadFile(file.sid || file.response.data.sid, file.name, '.' + file.suffix)
    }
  }
}
</script>
